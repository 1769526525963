export const STREET_NAMES = [
	"Windmill Road",
	"Church Walk",
	"School Hill",
	"Scott Street",
	"Church Close",
	"Union Road",
	"Bell Street",
	"Beechwood Road",
	"Balmoral Road",
	"The Gardens",
	"Hill Lane",
	"Claremont Road",
	"Rowan Avenue",
	"Salisbury Street",
	"Orchard Gardens",
	"Second Avenue",
	"Fox Close",
	"Byron Close",
	"Taylor Street",
	"West End",
	"Mary Street",
	"Brook Lane",
	"Nelson Road",
	"Partridge Close",
	"Walker Street",
	"Clarence Road",
	"Western Road",
	"Grasmere Road",
	"Clay Lane",
	"Ferry Road",
	"The Mount",
	"Byron Avenue",
	"Mount Pleasant",
	"Little Lane",
	"Middleton Road",
	"Woodland Way",
	"College Road",
	"Meadow Street",
	"Westfield Close",
	"Campion Close",
	"Church Way",
	"Fox Lane",
	"Vicarage Gardens",
	"The Glen",
	"Elm Avenue",
	"Manor Lane",
	"Holly Grove",
	"Woodlands Avenue",
	"Lime Grove",
	"Exeter Road",
	"Oak Street",
	"Red Lane",
	"Mill Street",
	"Bull Lane",
	"Union Street",
	"Moor Road",
	"Grove Lane",
	"Moorland Road",
	"The Butts",
	"The Avenue",
	"Manor Park",
	"Main Road",
	"Meadow Way",
	"York Avenue",
	"Wood Street",
	"Windermere Road",
	"The Croft",
	"Park View",
	"Highfield Avenue",
	"Hillcrest Avenue",
	"Riverside",
	"Meadow Close",
	"Hill Road",
	"Mount Pleasant Road",
	"Abbey Road",
	"Campbell Street",
	"Park Grove",
	"Park Street",
	"Mill Road",
	"Jubilee Road",
	"Fairfield",
	"Mulberry Close",
	"Herbert Road",
	"Buckingham Road",
	"Hall Close",
	"Adelaide Street",
	"Woodstock Road",
	"Grange Avenue",
	"Ivy Close",
	"Victoria Street",
	"Willow Lane",
	"George Street",
	"Lakeside",
	"Norfolk Road",
	"Ash Street",
	"Lancaster Avenue",
	"Windsor Road",
	"Wren Close",
	"Malvern Road",
	"Elizabeth Road",
];

export const CITIES = [
	"New York City, New York",
	"Los Angeles, California",
	"Chicago, Illinois",
	"Houston, Texas",
	"Phoenix, Arizona",
	"Philadelphia, Pennsylvania",
	"San Antonio, Texas",
	"San Diego, California",
	"Dallas, Texas",
	"San Jose, California",
	"Austin, Texas",
	"Jacksonville, Florida",
	"Fort Worth, Texas",
	"Columbus, Ohio",
	"Charlotte, North Carolina",
	"San Francisco, California",
	"Indianapolis, Indiana",
	"Seattle, Washington",
	"Denver, Colorado",
	"Washington, District of Columbia",
	"Boston, Massachusetts",
	"El Paso, Texas",
	"Nashville, Tennessee",
	"Detroit, Michigan",
	"Oklahoma City, Oklahoma",
	"Portland, Oregon",
	"Las Vegas, Nevada",
	"Memphis, Tennessee",
	"Louisville, Kentucky",
	"Baltimore, Maryland",
	"Milwaukee, Wisconsin",
	"Albuquerque, New Mexico",
	"Tucson, Arizona",
	"Fresno, California",
	"Mesa, Arizona",
	"Sacramento, California",
	"Atlanta, Georgia",
	"Kansas City, Missouri",
	"Colorado Springs, Colorado",
	"Omaha, Nebraska",
	"Raleigh, North Carolina",
	"Miami, Florida",
	"Long Beach, California",
	"Virginia Beach, Virginia",
	"Oakland, California",
	"Minneapolis, Minnesota",
	"Tulsa, Oklahoma",
	"Tampa, Florida",
	"Arlington, Texas",
	"New Orleans, Louisiana",
	"Wichita, Kansas",
	"Bakersfield, California",
	"Cleveland, Ohio",
	"Aurora, Colorado",
	"Anaheim, California",
	"Honolulu, Hawaii",
	"Santa Ana, California",
	"Riverside, California",
	"Corpus Christi, Texas",
	"Lexington, Kentucky",
	"Henderson, Nevada",
	"Stockton, California",
	"Saint Paul, Minnesota",
	"Cincinnati, Ohio",
	"St. Louis, Missouri",
	"Pittsburgh, Pennsylvania",
	"Greensboro, North Carolina",
	"Lincoln, Nebraska",
	"Anchorage, Alaska",
	"Plano, Texas",
	"Orlando, Florida",
	"Irvine, California",
	"Newark, New Jersey",
	"Durham, North Carolina",
	"Chula Vista, California",
	"Toledo, Ohio",
	"Fort Wayne, Indiana",
	"St. Petersburg, Florida",
	"Laredo, Texas",
	"Jersey City, New Jersey",
	"Chandler, Arizona",
	"Madison, Wisconsin",
	"Lubbock, Texas",
	"Scottsdale, Arizona",
	"Reno, Nevada",
	"Buffalo, New York",
	"Gilbert, Arizona",
	"Glendale, Arizona",
	"North Las Vegas, Nevada",
	"Winston–Salem, North Carolina",
	"Chesapeake, Virginia",
	"Norfolk, Virginia",
	"Fremont, California",
	"Garland, Texas",
	"Irving, Texas",
	"Hialeah, Florida",
	"Richmond, Virginia",
	"Boise, Idaho",
	"Spokane, Washington",
	"Baton Rouge, Louisiana",
	"Tacoma, Washington",
	"San Bernardino, California",
	"Modesto, California",
	"Fontana, California",
	"Des Moines, Iowa",
	"Moreno Valley, California",
	"Santa Clarita, California",
	"Fayetteville, North Carolina",
	"Birmingham, Alabama",
	"Oxnard, California",
	"Rochester, New York",
	"Port St. Lucie, Florida",
	"Grand Rapids, Michigan",
	"Huntsville, Alabama",
	"Salt Lake City, Utah",
	"Frisco, Texas",
	"Yonkers, New York",
	"Amarillo, Texas",
	"Glendale, California",
	"Huntington Beach, California",
	"McKinney, Texas",
	"Montgomery, Alabama",
	"Augusta, Georgia",
	"Aurora, Illinois",
	"Akron, Ohio",
	"Little Rock, Arkansas",
	"Tempe, Arizona",
	"Columbus, Georgia",
	"Overland Park, Kansas",
	"Grand Prairie, Texas",
	"Tallahassee, Florida",
	"Cape Coral, Florida",
	"Mobile, Alabama",
	"Knoxville, Tennessee",
	"Shreveport, Louisiana",
	"Worcester, Massachusetts",
	"Ontario, California",
	"Vancouver, Washington",
	"Sioux Falls, South Dakota",
	"Chattanooga, Tennessee",
	"Brownsville, Texas",
	"Fort Lauderdale, Florida",
	"Providence, Rhode Island",
	"Newport News, Virginia",
	"Rancho Cucamonga, California",
	"Santa Rosa, California",
	"Peoria, Arizona",
	"Oceanside, California",
	"Elk Grove, California",
	"Salem, Oregon",
	"Pembroke Pines, Florida",
	"Eugene, Oregon",
	"Garden Grove, California",
	"Cary, North Carolina",
	"Fort Collins, Colorado",
	"Corona, California",
	"Springfield, Missouri",
	"Jackson, Mississippi",
	"Alexandria, Virginia",
	"Hayward, California",
	"Clarksville, Tennessee",
	"Lakewood, Colorado",
	"Lancaster, California",
	"Salinas, California",
	"Palmdale, California",
	"Hollywood, Florida",
	"Springfield, Massachusetts",
	"Macon, Georgia",
	"Kansas City, Kansas",
	"Sunnyvale, California",
	"Pomona, California",
	"Killeen, Texas",
	"Escondido, California",
	"Pasadena, Texas",
	"Naperville, Illinois",
	"Bellevue, Washington",
	"Joliet, Illinois",
	"Murfreesboro, Tennessee",
	"Midland, Texas",
	"Rockford, Illinois",
	"Paterson, New Jersey",
	"Savannah, Georgia",
	"Bridgeport, Connecticut",
	"Torrance, California",
	"McAllen, Texas",
	"Syracuse, New York",
	"Surprise, Arizona",
	"Denton, Texas",
	"Roseville, California",
	"Thornton, Colorado",
	"Miramar, Florida",
	"Pasadena, California",
	"Mesquite, Texas",
	"Olathe, Kansas",
	"Dayton, Ohio",
	"Carrollton, Texas",
	"Waco, Texas",
	"Orange, California",
	"Fullerton, California",
	"Charleston, South Carolina",
	"West Valley City, Utah",
	"Visalia, California",
	"Hampton, Virginia",
	"Gainesville, Florida",
	"Warren, Michigan",
	"Coral Springs, Florida",
	"Cedar Rapids, Iowa",
	"Round Rock, Texas",
	"Sterling Heights, Michigan",
	"Kent, Washington",
	"Columbia, South Carolina",
	"Santa Clara, California",
	"New Haven, Connecticut",
	"Stamford, Connecticut",
	"Concord, California",
	"Elizabeth, New Jersey",
	"Athens, Georgia",
	"Thousand Oaks, California",
	"Lafayette, Louisiana",
	"Simi Valley, California",
	"Topeka, Kansas",
	"Norman, Oklahoma",
	"Fargo, North Dakota",
	"Wilmington, North Carolina",
	"Abilene, Texas",
	"Odessa, Texas",
	"Columbia, Missouri",
	"Pearland, Texas",
	"Victorville, California",
	"Hartford, Connecticut",
	"Vallejo, California",
	"Allentown, Pennsylvania",
	"Berkeley, California",
	"Richardson, Texas",
	"Arvada, Colorado",
	"Ann Arbor, Michigan",
	"Rochester, Minnesota",
	"Cambridge, Massachusetts",
	"Sugar Land, Texas",
	"Lansing, Michigan",
	"Evansville, Indiana",
	"College Station, Texas",
	"Fairfield, California",
	"Clearwater, Florida",
	"Beaumont, Texas",
	"Independence, Missouri",
	"Provo, Utah",
	"West Jordan, Utah",
	"Murrieta, California",
	"Palm Bay, Florida",
	"El Monte, California",
	"Carlsbad, California",
	"North Charleston, South Carolina",
	"Temecula, California",
	"Clovis, California",
	"Springfield, Illinois",
	"Meridian, Idaho",
	"Westminster, Colorado",
	"Costa Mesa, California",
	"High Point, North Carolina",
	"Manchester, New Hampshire",
	"Pueblo, Colorado",
	"Lakeland, Florida",
	"Pompano Beach, Florida",
	"West Palm Beach, Florida",
	"Antioch, California",
	"Everett, Washington",
	"Downey, California",
	"Lowell, Massachusetts",
	"Centennial, Colorado",
	"Elgin, Illinois",
	"Richmond, California",
	"Peoria, Illinois",
	"Broken Arrow, Oklahoma",
	"Miami Gardens, Florida",
	"Billings, Montana",
	"Jurupa Valley, California",
	"Sandy Springs, Georgia",
	"Gresham, Oregon",
	"Lewisville, Texas",
	"Hillsboro, Oregon",
	"Ventura[ab], California",
	"Greeley, Colorado",
	"Inglewood, California",
	"Waterbury, Connecticut",
	"League City, Texas",
	"Santa Maria, California",
	"Tyler, Texas",
	"Davie[ac], Florida",
	"Daly City, California",
	"Boulder, Colorado",
	"Allen, Texas",
	"West Covina, California",
	"Sparks, Nevada",
	"Wichita Falls, Texas",
	"Green Bay, Wisconsin",
	"San Mateo, California",
	"Norwalk, California",
	"Rialto, California",
	"Las Cruces, New Mexico",
	"Chico, California",
	"El Cajon, California",
	"Burbank, California",
	"South Bend, Indiana",
	"Renton, Washington",
	"Vista, California",
	"Davenport, Iowa",
	"Edinburg, Texas",
	"Tuscaloosa, Alabama",
	"Carmel, Indiana",
	"Spokane Valley, Washington",
	"San Angelo, Texas",
	"Vacaville, California",
	"Bend, Oregon",
];
