import styles from "./SearchPage.module.css";
import getAds from "../ads";

import { useLocation } from "react-router-dom";
import queryString from "query-string";

const MAX_ADS = 10;

export default function SearchPage() {
	const { search } = useLocation();
	const [ads, moreResults] = getAds(queryString.parse(search).q, MAX_ADS);
	const noResults = ads.length === 0;

	return (
		<div className={styles.page}>
			<div className={styles.contents}>
				<div className={styles.title}>
					{noResults ? "No ads match your search" : "Search Results"}
				</div>
				<AdGrid ads={ads} />
				{moreResults ? (
					<div
						className={styles.more}
					>{`Showing first ${MAX_ADS} listings`}</div>
				) : null}
			</div>
		</div>
	);
}

function AdGrid({ ads }) {
	return (
		<div className={styles.grid}>
			{ads.map((ad, i) => (
				<Ad key={i} ad={ad} />
			))}
		</div>
	);
}

function Ad({ ad }) {
	return (
		<div className={styles.ad}>
			<div
				className={styles.ad__imageWrapper}
				style={{ backgroundImage: `url(${ad.image})` }}
			></div>
			{ad.overlay ? (
				<div className={styles.ad__overlay}>{ad.overlay}</div>
			) : null}
			<div className={styles.ad__details}>
				<div className={styles.ad__price}>{ad.price}</div>
				<div className={styles.ad__description}>{ad.description}</div>
				<div className={styles.ad__address}>{ad.address}</div>
			</div>
		</div>
	);
}
