import { STREET_NAMES, CITIES } from "./ads_consts";

import lunr from "lunr";
import { forEach, random, range } from "lodash";

const NO_IMAGE_IMAGES = [
    require("./media/ads/no-image1.png").default,
    require("./media/ads/no-image2.png").default,
];

const ADS = range(1000)
    .map(randomAd)
    .concat([
        {
            image: require("./media/ads/house9871923.jpg").default,
            price: "$186,000",
            overlay: "House for sale",
            description: "3 bds 4 ba 1,899 sqft",
            address: "3620 South Robb Street, Denver, Colorado",
        },
        // Buried-Alive-1
        {
            image: require("./media/ads/no-image1.png").default,
            price: "$142,000",
            overlay: "House for sale",
            description: "3 bds 4 ba 2,429 sqft",
            address: "44 Mountain Drive, Vestola Forest",
        },
        {
            image: require("./media/ads/no-image2.png").default,
            price: "$149,000",
            overlay: "House for sale",
            description: "2 bds 3 ba 2,811 sqft",
            address: "13 Mountain Drive, Denver",
        },
        {
            image: require("./media/ads/no-image2.png").default,
            price: "$122,000",
            overlay: "House for sale",
            description: "2 bds 3 ba 1,972 sqft",
            address: "26 Mountain Drive, Michigan",
        },
        {
            image: require("./media/ads/no-image1.png").default,
            price: "$129,000",
            overlay: "House for sale",
            description: "2 bds 3 ba 2,788 sqft",
            address: "7 Mountain Drive, Oregon",
        },
        {
            image: require("./media/ads/no-image1.png").default,
            price: "$269,000",
            overlay: "House for sale",
            description: "2 bds 3 ba 2,102 sqft",
            address: "49 Mountain Drive, Venice Beach",
        },
        {
            image: require("./media/ads/no-image2.png").default,
            price: "$158,800",
            overlay: "House for sale",
            description: "3 bds 3 ba 2,483 sqft",
            address: "32 Venice Blvd, Vestola Forest",
        },
        // {
        //     image: require("./media/ads/no-image1.png").default,
        //     price: "$126,000",
        //     overlay: "House for sale",
        //     description: "2 bds 3 ba 2,031 sqft",
        //     address: "101 Kennedy Dr, Vestola Forest",
        // },
        {
            image: require("./media/ads/no-image2.png").default,
            price: "$151,000",
            overlay: "House for sale",
            description: "3 bds 3 ba 2,522 sqft",
            address: "12 Nixon St, Vestola Forest",
        },
        {
            image: require("./media/ads/no-image1.png").default,
            price: "$146,000",
            overlay: "House for sale",
            description: "3 bds 3 ba 2,669 sqft",
            address: "69 Fun St, Vestola Forest",
        },
        //PHONE part 5
        {
            image: require("./media/ads/no-image1.png").default,
            price: "Click Link Below",
            overlay: "House for sale",
            description: (
                <a
                    href="https://top.social/richard-dalton"
                    target="_blank"
                    rel="noreferrer"
                >
                    {"top.social/richard-dalton"}
                </a>
            ),
            address: "Ciadel Drive, Queensland",
        },
        //Criminal Defensive part 5
        {
            image: require("./media/ads/house2318976.jpg").default,
            price: "381,000",
            overlay: "House for sale",
            description: "Call for details: 978-555-0122",
            address: "Frogmore",
        },
        {
            image: require("./media/ads/no-image1.png").default,
            price: "186,000",
            overlay: "House for sale",
            description: "2 bds 2 ba 1,400 sqft",
            address: "23 Pinecrest Drive, Frogmore",
        },
        {
            image: require("./media/ads/no-image1.png").default,
            price: "255,000",
            overlay: "House for sale",
            description: "3 bds 3 ba 2,200 sqft",
            address: "42 Willow Lane, Frogmore",
        },
        {
            image: require("./media/ads/no-image1.png").default,
            price: "348,000",
            overlay: "House for sale",
            description: "5 bds 4 ba 2,930 sqft",
            address: "17 Oakwood Terrace, Frogmore",
        },
        {
            image: require("./media/ads/no-image1.png").default,
            price: "221,000",
            overlay: "House for sale",
            description: "2 bds 2 ba 1,640 sqft",
            address: "88 Maple Street, Frogmore",
        },
    ]);

export default function getAds(searchQuery, maxResults) {
    if (!searchQuery || searchQuery === "") {
        return [];
    }

    const idx = lunr(function () {
        this.ref("id");
        this.field("address");

        forEach(ADS, (ad, adId) => {
            this.add({
                id: adId,
                address: ad.address,
            });
        });
    });

    let results = idx.search(`${searchQuery}~1`);
    const numResults = results.length;

    if (maxResults) {
        results = results.slice(0, maxResults);
    }

    return [
        results.map((result) => ADS[result.ref]),
        maxResults && numResults > maxResults,
    ];
}

function randomAd() {
    const price = random(61, 700);
    const beds = random(1, 5);
    const bath = random(1, 3);
    const sqft = random(400, 4000);
    const number = random(1, 800) * 10;
    const street = randomChoice(STREET_NAMES);
    const city = randomChoice(CITIES);

    return {
        price: `$${price},000`,
        image: randomChoice(NO_IMAGE_IMAGES),
        overlay: "House for sale",
        description: `${beds} bds ${bath} ba ${sqft} sqft`,
        address: `${number} ${street}, ${city}`,
    };
}

function randomChoice(arr) {
    return arr[random(arr.length - 1)];
}
