import styles from "./SiteHeader.module.css";

import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";

export default function SiteHeader() {
	const { pathname } = useLocation();
	const innerPage = pathname !== "/";

	return (
		<header className={classnames(styles.header, { innerPage })}>
			<Link to="/">
				<img
					src={require("../media/logo.png").default}
					className={styles.logo}
					alt="Settlar"
				/>
			</Link>
		</header>
	);
}
