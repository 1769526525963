import styles from "./HomePage.module.css";

import { useRef } from "react";
import { useHistory } from "react-router-dom";

export default function HomePage() {
	return (
		<div className={styles.home}>
			<div className={styles.contents}>
				<h1 className={styles.title}>
					{"Happiness doesn't have just one address"}
				</h1>
				<SearchBox />
			</div>
		</div>
	);
}

function SearchBox() {
	const textboxRef = useRef();
	const history = useHistory();

	function handleSubmit(evt) {
		evt.preventDefault();

		const value = textboxRef.current.value.trim();
		if (value !== "") {
			history.push(`/search?q=${value}`);
		}
	}

	return (
		<form className={styles.search} onSubmit={handleSubmit}>
			<input
				ref={textboxRef}
				type="text"
				className={styles.search__input}
				placeholder="Enter a street name, city, or state"
			/>
			<button className={styles.search__button} onClick={handleSubmit}>
				<img
					className={styles.search__button__img}
					src={require("../media/icon-search.svg").default}
					alt="search"
				/>
			</button>
		</form>
	);
}
