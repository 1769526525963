import SiteHeader from "./components/SiteHeader";
import HomePage from "./components/HomePage";
import SearchPage from "./components/SearchPage";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
    return (
        <div className="App">
            <Router>
                <SiteHeader />
                <Switch>
                    <Route path="/search" exact>
                        <SearchPage />
                    </Route>
                    <Route path="/" exact>
                        <HomePage />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
